/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'person-gear': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M11 5a3 3 0 11-6 0 3 3 0 016 0M8 7a2 2 0 100-4 2 2 0 000 4m.256 7a4.5 4.5 0 01-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 00.921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 00.382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 00-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 00-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 00-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 00-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 00.382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 00.92-.382zM14 12.5a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0"/>',
    },
});
